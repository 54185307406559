import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { store } from './app/store';
import App from './App';
import './services/i18n';

// Initialize GTM with your GTM ID
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID, // although it is the same id for both TEST and PROD, keeping it as an env variable for flexibility.
  auth: process.env.REACT_APP_GTM_AUTH, // needed to control environment TEST or PROD
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  maxValueLength: 1000, // Increase the maximum length of log messages

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(process.env.REACT_APP_TRACE_SAMPLE_RATE),
});

function FallbackComponent() {
  return <div>An error has occured</div>;
}

root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>
);
