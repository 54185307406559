/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import {
  Checkbox,
  TextField,
  Banner,
  MaterialIcon,
  SpinningIcon,
  MTRFullLogo,
} from '@dsny/dsny-component-library';
import { useForm } from 'react-hook-form';
import theme from 'src/styles/theme';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  AuthForm,
  getSessionUser,
  signIn,
} from 'src/features/Auth/Auth.thunks';
import { inputHandler } from 'src/utils/InputHandler';
import {
  FormBox,
  CheckboxWrapper,
  Title,
  Container,
  ButtonWrapper,
  RememberMeSection,
  NotMemberSection,
  JoinCommunityLink,
  ForgotPasswordLink,
  LogoMTRWrapper,
  ButtonSection,
  ErrorWrapper,
} from './Signin.styles';
import { AuthWrapper } from '../Auth.styles';
import { clearSignupResponse } from '../Auth.slice';
import {
  identifyUser,
  trackEvent,
  initializeEventWrapper,
} from '../../../utils/segmentService';

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

const EMAIL_FIELD = 'email';
const PASSWORD_FIELD = 'password';

const Signin: React.FC = () => {
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useState<string>('');
  const [rerender, setRerender] = useState(false);
  const [tailingIconName, setTailingIconName] = useState('VisibilityOff');
  const [passwordType, setPasswordType] = useState('password');
  const { isFetching, isLoginSucessful, isUserEligibleForMtr } = useSelector(
    (state: RootState) => state.auth
  );
  const { email } = useSelector(
    (state: RootState) => state.auth.signUp.stepOne
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      dispatch(clearSignupResponse());
    }
  }, [email]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver<any>(schema),
    shouldUnregister: true,
  });

  useEffect(() => {
    const rememberMeItem = localStorage.getItem('PLAYMPE_REMEMBERME');
    if (rememberMeItem) {
      setValue(EMAIL_FIELD, rememberMeItem);
      setRememberMe('checkValue');
    }
  }, [setValue]);

  // Setting remember me on local storage
  const addEmailToLocalStorage = (addValue: boolean) => {
    if (addValue && getValues(EMAIL_FIELD)) {
      localStorage.setItem('PLAYMPE_REMEMBERME', getValues(EMAIL_FIELD));
    } else {
      localStorage.removeItem('PLAYMPE_REMEMBERME');
    }
  };

  // Handling input field
  const handleInput = () => {
    setRerender(!rerender);
    if (!!rememberMe && getValues(EMAIL_FIELD)) {
      addEmailToLocalStorage(!!rememberMe);
    }
  };

  // Handling onSubmit button
  const onSubmit = (data: AuthForm) => {
    setRerender(!rerender);
    data.identifier = uuid().replace(/-/gi, '');
    if (isFetching) return;
    dispatch(signIn(data))
      .unwrap()
      .then(() => {
        const user = getSessionUser();
        identifyUser(user.staffid, {
          email: user.email,
          name: user.fullname,
          username: user.username,
          company_id: user.company_id,
        });
        initializeEventWrapper(user.company_id);
        trackEvent('Login', {}, () => {
          navigate('/dashboard');
        });
      })
      .catch(() => {
        // Do not navigate when 401 or 403
      });
  };

  // Handling checkbox
  const handleCheckbox = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const checked = target.value;
    setRememberMe(checked ? '' : 'checkValue');
    addEmailToLocalStorage(!checked);
  };

  // Handling password visibility
  const handlePasswordVisibility = () => {
    if (tailingIconName === 'VisibilityOff') {
      setTailingIconName('Visibility');
      setPasswordType('text');
    } else {
      setTailingIconName('VisibilityOff');
      setPasswordType('password');
    }
  };

  // Incorrect credentials error message
  const credentialErrorMessage = {
    title: t('SIGNIN_INCORRECT_CREDENTIALS'),
    description: t('SIGNIN_INVALID_FIELDS'),
  };

  // User not eligible for MTR error message
  const eligibilityErrorMessage = {
    title: t('SIGNIN_MULTIPLE_CASTER_COMPANIES_ERROR'),
    description: t('SIGNIN_MULTIPLE_CASTER_COMPANIES_ERROR_DESC'),
  };

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      <Container>
        <FormBox onSubmit={handleSubmit(onSubmit as any)}>
          {/* Incorrect credentials error message */}
          {!isLoginSucessful && (
            <ErrorWrapper>
              <Banner bannerContent={credentialErrorMessage} variant="error" />
            </ErrorWrapper>
          )}

          {/* User not eligible for MTR error message */}
          {!isUserEligibleForMtr && (
            <ErrorWrapper>
              <Banner bannerContent={eligibilityErrorMessage} variant="error" />
            </ErrorWrapper>
          )}

          <Title>{t('SIGNIN_TITLE')}</Title>
          <TextField
            id="email"
            data-cy="email"
            setting="mtr"
            labelText={t('SIGNIN_USERNAME')}
            type="text"
            required
            {...register(EMAIL_FIELD)}
            ref={null}
            error={errors?.email && t('SIGNIN_ERROR_USERNAME')}
            onBlur={(e: Event) => {
              inputHandler(setValue, trigger, e);
              handleInput();
            }}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleInput();
            }}
            value={getValues(EMAIL_FIELD) || ''}
            onFocus={() => setRerender(!rerender)}
            wrapperStyles={{
              paddingRight: '0px',
              paddingBottom: '32px',
              marginBottom: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
              borderColor: errors?.email && theme.colors.error50,
            }}
            labelStyle={{
              fontSize: '14px',
              color: errors?.email && theme.colors.error50,
            }}
            errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
          />
          <TextField
            id="password"
            data-cy="password"
            setting="mtr"
            labelText={t('SIGNIN_PASSWORD')}
            type={passwordType}
            required
            {...register(PASSWORD_FIELD)}
            ref={null}
            error={errors?.password && t('SIGNIN_ERROR_PASSWORD')}
            onBlur={(e: Event) => {
              inputHandler(setValue, trigger, e);
              handleInput();
            }}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleInput();
            }}
            onClick={() => handlePasswordVisibility()}
            onFocus={() => setRerender(!rerender)}
            wrapperStyles={{
              paddingRight: '0px',
              paddingBottom: '0px',
              marginBottom: '16px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
              borderColor: errors?.password && theme.colors.error50,
            }}
            labelStyle={{
              fontSize: '14px',
              color: errors?.password && theme.colors.error50,
            }}
            errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
            tailingIcon={tailingIconName}
            isTailingClickable
            tailingStyle={{
              paddingTop: errors?.password ? '6px' : '20px',
              paddingRight: '10px',
            }}
          />

          <RememberMeSection>
            <CheckboxWrapper>
              <Checkbox
                id="checkValue"
                name="rememberMe"
                data-cy="remember_me"
                color={theme.colors.green}
                onChange={handleCheckbox}
                value={rememberMe}
                checked={!!rememberMe}
                style={{
                  borderColor: theme.colors.neutralW70,
                  marginRight: '8px',
                  marginBottom: '4px',
                }}
              />
              <span>{t('SIGNIN_REMEMBER_ME')}</span>
            </CheckboxWrapper>

            <ForgotPasswordLink to="/auth/forgot_password">
              {t('SIGNIN_FORGOT_PASSWORD_LINK')}
            </ForgotPasswordLink>
          </RememberMeSection>

          <ButtonSection>
            <ButtonWrapper
              setting="mtr"
              fontSize="14px"
              type="submit"
              data-cy="sign_in"
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
                paddingTop: isFetching ? '14px' : '',
              }}
            >
              {isFetching ? (
                <SpinningIcon>
                  <MaterialIcon name="Rotate" />
                </SpinningIcon>
              ) : (
                t('SIGNIN_CTA_BUTTON')
              )}
            </ButtonWrapper>
          </ButtonSection>

          <NotMemberSection>
            {t('SIGNIN_NOT_A_MEMBER')}&nbsp;
            <JoinCommunityLink to="/auth/signup">
              {t('SIGNIN_JOIN_COMMUNITY_LINK')}
            </JoinCommunityLink>
          </NotMemberSection>
        </FormBox>
      </Container>
    </AuthWrapper>
  );
};

export default Signin;
